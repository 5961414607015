import React, {Component} from 'react';
import {PayPalButton} from "react-paypal-button-v2";

class Buy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            data: null,
            error: null,
            complete: false,
            idTransac: null,
            name: null,
            discount: 0
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "linear-gradient(276deg, #49a09d, #5f2c82)"
        document.body.style.backgroundSize = "cover"
        document.body.style.height = "100%"

        let opts = {
            method: "GET",
            headers: {"Content-Type": "application/json", "Authorization": "Bearer " + this.props.match.params.key}
        }
        fetch("https://moovingdev.fr:3003/factures/" + this.props.match.params.facid, opts)
            .then(res => res.json())
            .then((result) => {
                if(result.error){
                    this.setState({
                        isLoaded: false,
                        error: {message: "Introuvable"}
                    })
                    return;
                }
                if(result.complete){
                    this.setState({
                        isLoaded: true,
                        complete: true
                    })
                    return;
                }
                let discount = 0;
                for (const articleKey in result.articles) {
                    let a = result.articles[articleKey]
                    discount += (a.prix*a.quantite)-a.total
                }
                let realTotal = result.articles.reduce((a,{total}) => a + total, 0.0)

                realTotal -= result.total

                this.setState({
                    isLoaded: true,
                    data: result,
                    discount: (realTotal + discount).toFixed(2),
                    totalWithoutDiscount: result.total + discount + realTotal
                })
            }, () => {
                this.setState({isLoaded: false, error: {message: "Cette facture n'est plus valide"}})
            })
    }
    createOrder(d, actions){
        console.log(this.state.data.facturenumber)
            return actions.order.create({
                "application_context":{
                    "shipping_preference": "NO_SHIPPING"
                },
                "business_name":{
                    "business_name": "MoovingDev"
                },
                "purchase_units": [
                    {
                        "invoice_id": this.state.data.facturenumber.split(' ')[1],
                        "description": this.state.data.facturenumber,
                        "amount": {
                            "currency_code":  (this.state.data.devise === '$' ? "USD" : "EUR"),
                            "value": this.state.data.total,
                            "breakdown": {
                                "item_total": { "currency_code": (this.state.data.devise === '$' ? "USD" : "EUR"), "value":this.state.totalWithoutDiscount},
                                "discount": {"currency_code": (this.state.data.devise === '$' ? "USD" : "EUR"), "value": this.state.discount}
                            }
                        },
                        "items": this.state.data.articles.map(a => (
                            {
                                "name": a.type,
                                "description": a.description.slice(0, 127),
                                "unit_amount":{
                                    "currency_code":  (this.state.data.devise === '$' ? "USD" : "EUR"),
                                    "value": a.prix
                                },
                                "quantity": a.quantite,
                                "category": "DIGITAL_GOODS"
                            }
                        ))
                    }
                ]
            })
    }

    success(details, d){
        console.log("Transaction success!")
        let opts = {
            method: "PATCH",
            headers: {"Content-Type": "application/json", "Authorization": "Bearer " + this.props.match.params.key},
            body: JSON.stringify({complete: true})
        }
        fetch("https://moovingdev.fr:3003/factures/" + this.props.match.params.facid, opts).then(() => {
            this.setState({complete: true, idTransac: d.orderID, name: details.payer.name.given_name})

        })

    }
    render() {
        const {error, isLoaded, data, complete, idTransac, name} = this.state;

        if(complete && name){
            return <div className={"buyPage"}>
        <h1>Merci {name}!</h1>
        <h2>Transaction n°{idTransac}</h2>
        </div>

        }
        if(complete){
            return <div className={"buyPage"}>
                <h1>Cette facture a déjà été payée</h1>
            </div>
        }
        if (error) {
            return <div>Erreur : {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Chargement…</div>;
        } else {
            return (
                <main className={"buyPage"}>
                    <h1>Bonjour !</h1>
                    <h2>{data.facturenumber}</h2>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                Type
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Prix unitaire
                            </th>
                            <th>
                                Quantité
                            </th>
                            <th>
                                Total HT
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.articles.map((article, k) => (
                            <tr key={k}>
                                <td>{article.type}</td>
                                <td>{article.description.split('\n').map((p, k) => (
                                    <p key={k}>{p}</p>
                                ))}</td>
                                <td>{article.prix}{data.devise}</td>
                                <td>{article.quantite}</td>
                                <td>{article.total}{data.devise}</td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                    <h2>Le total de votre commande est de: </h2><h1 className={"price"}>{data.total}{data.devise}</h1>
                    <div className={"paypal"}>
                    <PayPalButton
                        options={{
                            clientId: "AUHKxI175mjj8I8--Zm1pWFgM59rroEYEOCowf3rKlNkp9_-_7ZinN1cKA7jUUrBV0f8cXcxdukK7OAx",
                            currency: data.devise === '$' ? "USD" : "EUR"
                        }}
                    //amount={data.total}
                        currency={ data.devise === '$' ? "USD" : "EUR"}
                        onSuccess={this.success.bind(this)}
                        createOrder={this.createOrder.bind(this)}
                    >

                    </PayPalButton>
                    </div>
                </main>

            );

        }
    }
}
export default Buy