import React from 'react';
import Navigation from "../components/Navigation";
import SocialNetwork from "../components/SocialNetwork";
import Logo from "../components/Logo";
import {motion} from "framer-motion";
import {NavLink} from "react-router-dom";
const Contact = () => {
    const variants = {
        initial: {
            opacity: 0,
            transition: { duration: 0.5 },
            x: 200,
        },
        visible: {
            opacity: 1,
            x: 0,
        },
        exit: {
            opacity: 0.4,
            transition: { duration: 0.35 },
            x: -800,
        }
    };


    return (
        <main>
            <motion.div
                className={"contact"}
                exit="exit"
                animate="visible"
                initial="initial"
                variants={variants}
                >
            <Navigation/>
            <Logo/>
                <div className="contact-infos">
                    <div className="codeur">
                        <div className="content">
                            <h4>Disponible sur Codeur.com</h4>
                            <p onClick={() => window.location.href="https://www.codeur.com/-julienlys1q"}>Profil Codeur.com</p>
                        </div>
                    </div>
                    <div className="discord">
                        <div className={"content"}>
                            <h4>Discord</h4>
                            <p>Julrouxxx#0540</p>
                        </div>
                    </div>
                    <div className="email">
                        <div className="content">
                            <h4>Email</h4>
                            <p onClick={()=>window.location.href="mailto:contact@moovingdev.fr"}>contact@moovingdev.fr</p>
                        </div>
                    </div>
                    <SocialNetwork mobile={false}/>

                    <div className={"credits"}>
                        <p>
                            Julien Labbé - {new Date().getFullYear()}
                        </p>
                        <NavLink to={"/mentions-legales"} exact>
                            Mentions Légales
                        </NavLink>

                    </div>
                </div>

            </motion.div>
        </main>
    );
};

export default Contact;