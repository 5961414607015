import React from 'react';
import {motion} from "framer-motion";
import Navigation from "../components/Navigation";
import SocialNetwork from "../components/SocialNetwork";

const MentionsLegale = () => {
    const variants = {
        initial: {
            opacity: 0,
            transition: { duration: 0.5 },
            x: 200,
        },
        visible: {
            opacity: 1,
            x: 0,
        },
        exit: {
            opacity: 0.4,
            transition: { duration: 0.35 },
            x: -800,
        }
    };
    return (
        <main>
            <motion.div
                className="home"
                initial="initial"
                animate="visible"
                exit="exit"
                variants={variants}
            >
                <Navigation/>
                <SocialNetwork mobile={true}/>
                <div className={"home-main"}>

                <div className={"main-content"}>

                <h1>En construction</h1>
                </div>
                </div>
            </motion.div>
        </main>
    );
};

export default MentionsLegale;