import React, {useEffect} from 'react';

const DynamicText = () => {

    useEffect(() =>
    {
        let array = ["polyvalent", "efficace", "abordable"]
        let wordIndex = 0;
        let letterIndex = 0;
        const target = document.getElementById("text-target");
        let cursor;

        const createLetter = () =>{
            cursor?.remove();
            cursor = document.createElement("span");
            cursor.textContent = "|"
            cursor.style.animation = "cursor 500ms infinite"
            const letter = document.createElement("span");
            target.appendChild(letter);
            letter.classList.add("letter");
            letter.textContent = array[wordIndex][letterIndex];
            target.appendChild(cursor);
        }
        const removeLetter= ()=>{
            let d = 10;
            for (let i = target.children.length-2; i >= 0; i--) {
                let s = target.children[i];
                setTimeout(() => s.remove(), 80*d)
                d++;
            }
        }
        const loop = () => {
            setTimeout(() => {
                if(wordIndex >= array.length){
                    wordIndex = 0;
                    letterIndex = 0;
                    loop()
                }else if (letterIndex < array[wordIndex].length){
                    createLetter()
                    letterIndex++;
                    loop()
                }else{
                    removeLetter();
                    letterIndex = 0;
                    wordIndex++;
                    setTimeout(() => {
                        loop()
                    }, 2000)
                }
            }, 80)
        }
        loop()

    }, )
    return (
        <span className={"dynamic-text"}>
        <span className={"simply"}>Développement</span>
            <span id={"text-target"}>
            </span>
        </span>
    );
};

export default DynamicText;