import React, {useState} from 'react';

const Login = ({setToken}) => {

    async function login(e){
        setSuccess(false)
        setError(false);
        setDisabled(true)
        e.preventDefault()
        fetch('https://moovingdev.fr:3003/login', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username, password})
        }).then(r => r.json())
            .then(function (r){
            if(r.error){
                setError(true)
                setTimeout(() => setDisabled(false), 700)
                return;
            }
            setSuccess(true)
            setTimeout(() => setToken(r.token), 1000)
        })
    }
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    return (

        <div className={"loginWrapper"} style={{visibility: success ? "hidden" : "visible", opacity: success ? 0 : 1}}>
            <div className={"errorSpan"} style={{visibility: error ? "visible" : "hidden", opacity: error ? 1 : 0}}>
                Mauvais mot de passe ou identifiant
            </div>

            <form onSubmit={login}>
                <label><p>Nom d'utilisateur</p>
                    <input type={"text"} required onChange={e => setUsername(e.target.value)}/>
                </label>
                <label>
                    <p>
                        Mot de passe
                    </p>
                    <input type={"password"} required onChange={e => setPassword(e.target.value)}/>
                </label>
                <input disabled={disabled} type={"submit"} value={"Valider"}/>
            </form>
        </div>
    );

};

export default Login;