import React from 'react';
import Navigation from "../components/Navigation";
import SocialNetwork from "../components/SocialNetwork";
import DynamicText from "../components/DynamicText";
import Button from "../components/Button";
import Scroll from 'react-scroll';
import {motion} from "framer-motion";
let Element = Scroll.Element
const Home = () => {


    const variants = {
        initial: {
            opacity: 0,
            transition: { duration: 0.5 },
            x: 200,
        },
        visible: {
            opacity: 1,
            x: 0,
        },
        exit: {
            opacity: 0.4,
            transition: { duration: 0.35 },
            x: -800,
        }
    };

    return (
        <main>
            <motion.div
                className="home"
                initial="initial"
                animate="visible"
                exit="exit"
                variants={variants}
                >
            <Navigation/>
            <SocialNetwork mobile={true}/>
            <div className={"home-main"}>
                <div className={"main-content"}>
                    <h1>MoovingDev</h1>
                    <h2><DynamicText/></h2>
                    <Button text={"Voir les projets"} href={"projets"}/>

                </div>

            </div>


            </motion.div>

            <Element name={"projets"}/>
            <div className={"projets"}>
                <h1>Site en construction</h1>


            </div>

        </main>
    );
};

export default Home;