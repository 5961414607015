import React/*, {useEffect}*/ from 'react';
import {Switch, Route, Redirect/*, useHistory, useLocation*/} from 'react-router-dom';
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Buy from "./pages/Buy";
import {AnimatePresence} from "framer-motion";
import MentionsLegale from "./pages/MentionsLegale";
import Backoffice from "./pages/Backoffice";

const App = () => {
    /*const location = useLocation();
    const history = useHistory();

    useEffect(()=>
    {
        const handleScrollToElement = (e) => {
            const url = window.location.origin + "/";
            const wheelRouter = (after, before) =>
            {
                if(e.wheelDeltaY < 0) {
                    history.push(after)
                }else{
                    history.push(before)
                }
            }
            switch (window.location.href.toString()){
                case url: if(e.wheelDeltaY < 0){
                    history.push("contact")
                }
                break;
                case url + "contact":
                    if(e.wheelDeltaY > 0){
                        history.push("/")
                    }
                    break;
                default:
                    console.log("lol")
            }
        }
        window.addEventListener('wheel', handleScrollToElement)
    }, [history])
*/
    return (

        <AnimatePresence>

            <Switch /*location={location} key={location.pathname}*/>
                <Route exact path = "/" component={Home}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path={"/mentions-legales"} component={MentionsLegale}/>
                <Route path={"/buy/:facid/:key"} component={Buy}/>
                <Route path={"/backoffice/:facid?"} component={Backoffice}/>
                <Redirect to="/"/>
            </Switch>
        </AnimatePresence>
    );
};

export default App;