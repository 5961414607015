import React from 'react';
import Scroll from 'react-scroll'
class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hide: false}
    }

    scrollToElement(element) {
        Scroll.scroller.scrollTo(element, {smooth: true, duration: 1000});
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    componentDidMount() {
        window.onscroll = (e) =>
        {
            if(window.pageYOffset >= 50){
                this.setState({hide: true})

            }else{
                this.setState({hide: false})
            }
        }
    }
    render() {

        return (
            <div className={"scroll-bottom"} style={{opacity: this.state.hide ? "0" : "1", visibility: this.state.hide ? "hidden" : "visible"}}>
                <div className="button-container">
                    <button onClick={() => this.scrollToElement(this.props.href)} className="hover">
                        <span className="button">{this.props.text}</span>
                    </button>
                </div>
            </div>
        );
    };
}
export default Button;