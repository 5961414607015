import React from 'react';

const SocialNetwork = (props) => {
    const anim = () => {
        let navLinks = document.querySelectorAll('.social-network a');
        navLinks.forEach(link =>{
            link.addEventListener('mouseover', (e)=>
            {
                let attrX = e.offsetX - 20;
                let attrY = e.offsetY - 13;
                link.style.transform = `translate(${attrX}px, ${attrY}px)`;
            }
        )
        link.addEventListener('mouseleave', (e)=>
        {
            link.style.transform = "";
        })

        })
    }
    return (
        <div className={"social-network"}>
            <ul className={`content ${props.mobile ? 'block' : ''}`}>
                <a  href={"https://github.com/Julrouxxx"} target={"_blank"} rel={"noopener noreferrer"} className={"hover"} onMouseOver={anim}>
                    <li><i className={"fab fa-github"}/></li>
                </a>
                <a  href={"https://twitter.com/Julroux"} target={"_blank"} rel={"noopener noreferrer"} className={"hover"} onMouseOver={anim}>
                    <li><i className={"fab fa-twitter"}/></li>
                </a>
                <a  href={"https://www.linkedin.com/in/julien-labb%C3%A9-036757210/"} target={"_blank"} rel={"noopener noreferrer"} className={"hover"} onMouseOver={anim}>
                    <li><i className={"fab fa-linkedin"}/></li>
                </a>
            </ul>
        </div>
    );
};

export default SocialNetwork;