import React, {Component} from 'react';
import Login from "../components/Login";
import {Link, Redirect} from "react-router-dom";

class Backoffice extends Component {
    componentDidMount() {
        document.body.style.backgroundImage = "linear-gradient(276deg, #49a09d, #5f2c82)"
        document.body.style.backgroundSize = "cover"
        document.body.style.height = "100%"

        if(this.state.token){
            let opts = {
                method: "GET",
                headers: {"Content-Type": "application/json", "Authorization": "Bearer " + this.state.token},
            }
            fetch("https://moovingdev.fr:3003/factures", opts).then((r) => r.json()).then((r)=>{
                this.setState({factures: r})

            })

        }
    }
    constructor(props) {
        super(props);
        this.state = {
            token : sessionStorage.getItem("token"),
            factures: null
        }
    }

    getFactureById = (id) =>{
        return this.state.factures.filter(f => f.id === parseInt(id))[0]
    }


    setToken = (token) =>{
        sessionStorage.setItem("token", token)
        this.setState({token})
        this.componentDidMount()
    }
    delete = (f, confirm) =>{
        if(typeof confirm === "undefined"){
            this.delete(f, window.confirm("Voulez vous vraiment supprimer cette facture ?"))
            return;
        }
        if(!confirm) return;
        let opts = {
            method: "DELETE",
            headers: {"Content-Type": "application/json", "Authorization": "Bearer " + this.state.token}
        }
        fetch("https://moovingdev.fr:3003/factures/" + f.id, opts).then(r => r.json()).then((r)=> {
            this.setState({factures: r})
        })
    }
    changeStatus = (f, pos) => {
        let opts = {
            method: "PATCH",
            headers: {"Content-Type": "application/json", "Authorization": "Bearer " + this.state.token},
            body: JSON.stringify({complete: !f.complete})
        }
        fetch("https://moovingdev.fr:3003/factures/" + f.id, opts).then(r => r.json()).then((r)=> {
                let oldF = this.state.factures
                oldF[pos] = r
                this.setState(oldF)
            }
        )
    }

    render() {

        if(!this.state.token)
        return (
            <div>
                <Login setToken={this.setToken}/>
            </div>
        );
        if(!this.state.factures){
            return (
                <div>
                    <h4>Chargement...
                    </h4>
                </div>
            )
        }
        if(!this.props.match.params.facid)
            return(
                <div className={"backoffice"}>
                    <h1>Bienvenue !</h1>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                Numéro de facture
                            </th>
                            <th>
                                Total de la facture
                            </th>
                            <th>
                                Statut
                            </th>
                            <th>
                                Action
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {this.state.factures && this.state.factures.map((factures, f) => (
                            <tr key={factures.id}>
                                <td><Link to={"/backoffice/" + factures.id} >{factures.facturenumber}</Link></td>
                                <td>{factures.total}{factures.devise}</td>
                                <td>{factures.complete ? "Payée" : "En attente de paiement"}</td>
                                <td className={"buttonTable"}>
                                    {!factures.complete &&
                                    <button onClick={() => this.changeStatus(factures, f)} style={{backgroundColor: "green"}}>Marquer comme payée</button>
                                    }
                                    {factures.complete &&
                                    <button onClick={() => this.changeStatus(factures, f)} style={{backgroundColor: "orange"}}>Marquer comme impayée</button>
                                    }
                                    <button onClick={() => this.delete(factures)} style={{backgroundColor: "red"}}>Supprimer</button></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )
            this.selectedFac = this.getFactureById(this.props.match.params.facid)
        if(!this.selectedFac){
            return (<Redirect to={"/backoffice"}/>)
        }
            this.selectedPos = this.state.factures.indexOf(this.selectedFac)
            return (
                <div className={"backoffice"}>
                    <Link className={"backButton"} to={"/backoffice"}>Retourner aux factures</Link>
                    <h1>Bienvenue !</h1>
                    <h2>{ this.selectedFac.facturenumber}</h2>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>
                                Type
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Prix unitaire
                            </th>
                            <th>
                                Quantité
                            </th>
                            <th>
                                Total HT
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.selectedFac.articles.map((article, k) => (
                            <tr key={k}>
                                <td>{article.type}</td>
                                <td>{article.description.split('\n').map((p, k) => (
                                    <p key={k}>{p}</p>
                                ))}</td>
                                <td>{article.prix}{article.devise}</td>
                                <td>{article.quantite}</td>
                                <td>{article.total}{article.devise}</td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                    <div className={"buttonTable"} style={{display: "block"}}>
                    <h2>Le total de votre commande est de: </h2><h1 className={"price"}>{ this.selectedFac.total}{this.selectedFac.devise}</h1>
                    {!this.selectedFac.complete &&
                    <button onClick={() => this.changeStatus(this.selectedFac, this.selectedPos)} style={{backgroundColor: "green"}}>Marquer comme payée</button>
                    }
                    {this.selectedFac.complete &&
                    <button onClick={() => this.changeStatus(this.selectedFac, this.selectedPos)} style={{backgroundColor: "orange"}}>Marquer comme impayée</button>
                    }
                    <button onClick={() => this.delete(this.selectedFac)} style={{backgroundColor: "red"}}>Supprimer</button>
                    </div>
                </div>)
    }
}

export default Backoffice;